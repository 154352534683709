import {useCallback, useEffect, useState} from 'react';

import {useBugsnagContext} from '~/foundation/AppSetupContext';
import assertUnreachable from '~/utils/assertNever';

type StorageType = 'localStorage' | 'sessionStorage';
type StorageUpdateEvent<TValue> = CustomEvent<{
  key: string;
  value: TValue;
  storageType: StorageType;
}>;

const STORAGE_UPDATE_EVENT = 'storage:update';

const memoryStorage = {
  _data: {} as Record<string, string>,
  setItem(key: string, value: string) {
    this._data[key] = value;
  },
  getItem(key: string) {
    if (key in this._data) {
      return this._data[key];
    }
    return null;
  },
  removeItem(key: string) {
    delete this._data[key];
  },
};

function isFunction<T>(value: T | (() => T)): value is () => T {
  return typeof value === 'function';
}

/**
 * Used by both useLocalStorage and useSessionStorage hooks
 */
export function useStorage<TValue>(
  storageType: StorageType,
  keySuffix: string,
  initialValue: TValue | (() => TValue),
) {
  const key = `Discovery.${keySuffix}`;
  const {notify} = useBugsnagContext();

  const getStorage = useCallback(() => {
    // fall back to sessionStorage if localStorage is not available (incognito mode)
    const storagePreference =
      storageType === 'localStorage'
        ? (['localStorage', 'sessionStorage', 'memoryStorage'] as const)
        : (['sessionStorage', 'memoryStorage'] as const);

    for (const storageName of storagePreference) {
      try {
        const storage = (() => {
          switch (storageName) {
            case 'localStorage':
              return localStorage;
            case 'sessionStorage':
              return sessionStorage;
            case 'memoryStorage':
              return memoryStorage;
            default:
              return assertUnreachable(storageName);
          }
        })();

        const key = 'Discovery.something.we.will.not.use';
        storage.setItem(key, 'test');
        storage.removeItem(key);
        return storage;
        // eslint-disable-next-line no-empty
      } catch {}
    }

    notify(
      new Error(
        `Error using storage for ${storageType} with storagePreferences ${storagePreference.join(
          ', ',
        )}. Falling back to normal useState.`,
      ),
    );

    return undefined;
  }, [notify, storageType]);

  const [state, setState] = useState(() => {
    const getInitialValue = () => {
      if (isFunction(initialValue)) {
        return initialValue();
      }
      return initialValue;
    };
    let val: string | null = '';
    try {
      const storage = getStorage();
      if (!storage) {
        return getInitialValue();
      }

      val = storage.getItem(key);
      if (val === null) {
        return getInitialValue();
      }
      return JSON.parse(val) as TValue;
    } catch {
      notify(
        new Error(
          `useStorage: Failed to load key "${key}" with value "${val}".`,
        ),
        'warning',
      );
      return getInitialValue();
    }
  });

  useEffect(() => {
    getStorage()?.setItem(key, JSON.stringify(state));
  }, [getStorage, key, state]);

  // any time the state updates, let other listeners know
  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(STORAGE_UPDATE_EVENT, {
        detail: {key, value: state, storageType},
      }),
    );
  }, [key, state, storageType]);

  // subscribe to any storage events anywhere in the app and update this state
  useEffect(() => {
    const handler = (event: StorageUpdateEvent<TValue>) => {
      if (
        event.detail.key === key &&
        event.detail.storageType === storageType
      ) {
        setState(event.detail.value);
      }
    };
    // @ts-expect-error - this is a custom event that I don't want to add to the global space
    window.addEventListener(STORAGE_UPDATE_EVENT, handler);

    return () => {
      // @ts-expect-error - this is a custom event that I don't want to add to the global space
      window.removeEventListener(STORAGE_UPDATE_EVENT, handler);
    };
  }, [key, storageType]);

  return [state, setState] as const;
}
